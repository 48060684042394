(function ($) {
	$('.heroImg__slider').slick({
		autoplay: true,
		arrows: false,
		speed: 4000,
		fade: true,
	});

	$('.topFaq__question').on('click', function () { //タイトル要素をクリックしたら
		$('.topFaq__answer').slideUp(500); //クラス名.boxがついたすべてのアコーディオンを閉じる

		var findElm = $(this).next(".topFaq__answer"); //タイトル直後のアコーディオンを行うエリアを取得

		// $('.topFaq__item').removeClass('open');
		// $('.topFaq__item').addClass('open');
		if ($(this).hasClass('close')) { //タイトル要素にクラス名closeがあれば
			$(this).removeClass('close'); //クラス名を除去  
		} else { //それ以外は
			$('.close').removeClass('close'); //クラス名closeを全て除去した後
			$(this).addClass('close'); //クリックしたタイトルにクラス名closeを付与し
			$(findElm).slideDown(500); //アコーディオンを開く
		}
	});
})(jQuery);